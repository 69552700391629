import Header from "../Header/Header";

import { React, useState, useEffect } from "react";
import FirstStep from "../StepList/FirstStep";
import ThirdStep from "../StepList/ThirdStep";
import SecondStep from "../StepList/SecondStep";
import LastStep from "../StepList/LastStep";
import Loader from "../Loader/Loader";
import HistoryItem from "../History/HistoryItem";

import { СREATE_TRANSACTION, GET_REFERENCE_DATA } from "../../apollo";

import { useMutation, useLazyQuery } from "@apollo/client";

const EUR = "EUR";
const UAH = "UAH";

const HomePage = () => {
  let userId = localStorage.userId;
  const [createTransaction, { error }] = useMutation(СREATE_TRANSACTION);
  const [getReferenceData] = useLazyQuery(GET_REFERENCE_DATA);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, isLoading] = useState(true);
  const [historyItem, setHistoryItem] = useState(null);
  const [transactionData, setTransactionData] = useState({
    currencyFromAmount: null,
    currencyToAmount: null,
    recipient: null,
    mainCurrency: null,
    effectiveRate: null,
    transactionId: null,
  });

  useEffect(() => {
    const getData = async () => {
      const data = await getReferenceData({
        variables: {
          currencyFrom: EUR,
          currencyTo: UAH,
        },
      });

      const dataTransaction = await createTransaction({
        variables: {
          userId: userId,
          id: userId,
          currencyFrom: "EUR",
          currencyTo: "UAH",
          currencyFromAmount: 100,
        },
      });

      setTransactionData({
        ...data?.data?.getReferenceData,
        ...dataTransaction?.data?.createTransaction?.transaction,
        transactionId:
          dataTransaction?.data?.createTransaction?.transaction?.id,
        currencyFromAmount: null,
        currencyToAmount: null,
      });

      isLoading(false);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getScreen = () => {
    if (historyItem) {
      return (
        <HistoryItem
          userId={userId}
          historyItemId={historyItem}
          historyItemHandler={() => setHistoryItem(null)}
        />
      );
    }
    
    switch (activeStep) {
      case 0:
        return (
          <FirstStep
            userId={userId}
            nextStepHandler={() => setActiveStep((prev) => prev + 1)}
            historyItemHandler={(historyItem) => setHistoryItem(historyItem)}
            transactionData={transactionData}
            setTransactionData={(data) => setTransactionData(data)}
          />
        );
      case 1:
        return (
          <ThirdStep
            transactionData={transactionData}
            backHandler={() => setActiveStep(0)}
            setTransactionData={(data) => setTransactionData(data)}
            nextStepHandler={() => setActiveStep((prev) => prev + 1)}
          />
        );
      case 2:
        return (
          <SecondStep
            transactionData={transactionData}
            backHandler={() => setActiveStep(1)}
            setTransactionData={(data) => setTransactionData(data)}
            nextStepHandler={() => setActiveStep((prev) => prev + 1)}
          />
        );
      case 3:
        return (
          <LastStep
            transactionData={transactionData}
            backHandler={() => setActiveStep(2)}
            setTransactionData={(data) => setTransactionData(data)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="app">
      {activeStep === 0 && !historyItem && <Header />}
      {loading ? (
        <main className="app__main center container" role="main">
          <Loader />
        </main>
      ) : null}
      {/* {error ? <div className='app__main center container app__error'>Error! ${error.message}</div> : null} */}
      {!loading && !error && getScreen()}
    </div>
  );
};

export default HomePage;
