import React from "react";
import ErrorInfo from "../Errors/ErrorInfo";

const ErrorPage = () => {
  return (
    <div className="app container">
      <ErrorInfo/>
    </div>
  );
};

export default ErrorPage;
