import { useTranslation } from "react-i18next";

const Errors = ({ referenceData, message }) => {
  const { t } = useTranslation();
  const [range,currency] = message?.split("/");
  const amount = referenceData[range];
  const rangeText = range?.includes("min") ? t("min") : t("max");

  if(!message) return null;

  return (
    <div className="info__item error">
      <p>
        {t("amount")}  {" "}
          {rangeText}
          <span className="info__highlighted">{amount}  {currency}</span>
      </p>
    </div>
  );
};

export default Errors;
