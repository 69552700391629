import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackIcon } from "../../img/back.svg";
import { ReactComponent as LockIcon } from "../../img/lock.svg";
import { UPDATE_TRANSACTION } from "../../apollo";
import { useMutation } from "@apollo/client";
import cardValidator from "card-validator";
var regOnlyNumbers = /\d+$/;

function cc_format(value) {
  const v = value
    .replace(/\s+/g, "")
    .replace(/[^0-9]/gi, "")
    .substr(0, 16);
  const parts = [];

  for (let i = 0; i < v.length; i += 4) {
    parts.push(v.substr(i, 4));
  }

  return parts.length > 1 ? parts.join(" ") : value;
}

const SecondStep = ({
  backHandler,
  transactionData,
  setTransactionData,
  nextStepHandler,
}) => {
  const { t } = useTranslation();
  const { recipient } = transactionData;
  const [card, setCard] = useState(recipient ? cc_format(recipient) : "");
  const [isValid, setIsValid] = useState(recipient || false);
  const [isTouched, setIsTouched] = useState(false);
  const [updateTransaction] = useMutation(UPDATE_TRANSACTION);

  const blurHandler = (e) => {
    const value = e.target.value;
    setIsTouched(true);

    if (cardValidator.number(value).isPotentiallyValid && value.length === 19) {
      setTransactionData((prev) => {
        return {
          ...prev,
          recipient: value,
        };
      });
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const changeHandler = (e) => {
    const value = e.target.value;
    if (value && (value.length > 19 || !regOnlyNumbers.test(value))) {
      return;
    }

    if (cardValidator.number(value).isPotentiallyValid && value.length === 19) {
      setTransactionData((prev) => {
        return {
          ...prev,
          recipient: value,
        };
      });
      setIsValid(true);
    } else {
      if (isValid) {
        setIsValid(false);
      }
    }

    setCard(e.target.value);
  };

  const nextHandler = async () => {
    await updateTransaction({
      variables: {
        transactionId: transactionData.transactionId,
        recipient: card,
      },
    });

    nextStepHandler();
  };
  return (
    <>
      <main className="app__main container" role="main">
        <div className="app__title-wr">
          <button className="button button--back" onClick={backHandler}>
            <BackIcon />
          </button>
          <h2 className="app__title">{t("receipient")}</h2>
        </div>
        <div
          className={`input__item input__item--icon input__item--card ${
            !isValid && isTouched ? "error" : ""
          }`}
        >
          <div className="input__inner">
            <div className="input__inner-wrap">
              <label className="input__label-top" htmlFor="card">
                {t("cardNumber")}
              </label>
              <label className="input__icon" htmlFor="card">
                <LockIcon />
              </label>
              <input
                className="input"
                value={cc_format(card)}
                id="card"
                onChange={changeHandler}
                onBlur={blurHandler}
                placeholder="e.g. 4000 0000 0000 0003"
              />
            </div>
          </div>
          {!isValid && isTouched ? (
            <div className="input__description error"> {t("incorrect")}</div>
          ) : (
            <div className="input__description">{t("uCard")}</div>
          )}
        </div>
      </main>
      <div className="app__bottom">
        <button
          className="button"
          disabled={!isValid || !card}
          onClick={nextHandler}
        >
          {t("pay")}
        </button>
      </div>
    </>
  );
};

export default SecondStep;
