import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackIcon } from "../../img/back.svg";
import { UPDATE_TRANSACTION } from "../../apollo";
import { useMutation } from "@apollo/client";
const regexName = /^[a-zA-Z ,.'-]{1,100}$/;

const ThirdStep = ({
  backHandler,
  nextStepHandler,
  transactionData,
  setTransactionData,
}) => {
  const { t } = useTranslation();
  const [updateTransaction] = useMutation(UPDATE_TRANSACTION);
  const [isFormValid, setIsFormValid] = useState(
    transactionData.customerFirstName || false
  );
  const [values, setValues] = useState({
    name: transactionData.customerFirstName || "",
    email: transactionData.customerEmail || "",
    surname: transactionData.customerLastName || "",
  });

  const [validations, setValidations] = useState({
    name: "",
    email: "",
    surname: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const validateAll = () => {
    const { name, email, surname } = values;
    const validations = { name: "", email: "", surname: "" };
    let isValid = true;

    if (!name) {
      validations.name = t("required");
      isValid = false;
    }

    if ((name && name.length < 1) || name.length > 100) {
      validations.name = t("maxLength");
      isValid = false;
    }

    if (name && !regexName.test(name)) {
      validations.name = t("errorText");
      isValid = false;
    }

    if (!email) {
      validations.email = t("required");
      isValid = false;
    }

    if ((email && email.length < 1) || email.length > 100) {
      validations.email = t("maxLength");
      isValid = false;
    }

    if (email && !email.includes("@")) {
      validations.email = t("errorMail");
      isValid = false;
    }

    if (!surname) {
      validations.surname = t("required");
      isValid = false;
    }

    if ((surname && surname.length < 1) || surname.length > 100) {
      validations.surname = t("maxLength");
      isValid = false;
    }

    if (surname && !regexName.test(surname)) {
      validations.surname = t("errorText");
      isValid = false;
    }

    // if (!isValid) {
    //   setValidations(validations);
    // }

    return isValid;
  };

  const validateOne = (e) => {
    const { name } = e.target;
    const value = values[name];
    let message = "";

    if (!value) {
      message = t("required");
    }

    if (
      value &&
      (name === "name" || name === "surname") &&
      !regexName.test(value)
    ) {
      message = t("errorText");
    }

    if (value && (value.length < 1 || value.length > 100)) {
      message = t("maxLength");
    }

    if (value && name === "email" && !value.includes("@")) {
      message = t("errorMail");
    }

    setValidations({ ...validations, [name]: message });
    setIsFormValid(validateAll());
  };

  const nextHandler = async () => {
    setIsFormValid(false);

    setTransactionData((prev) => {
      return {
        ...prev,
        customerEmail: email,
        customerLastName: surname,
        customerFirstName: name,
      };
    });

    await updateTransaction({
      variables: {
        transactionId: transactionData.transactionId,
        customerEmail: email,
        customerLastName: surname,
        customerFirstName: name,
      },
    });

    nextStepHandler();
  };

  const { name, email, surname } = values;
  const { name: nameVal, email: emailVal, surname: surnameVal } = validations;

  return (
    <>
      <main className="app__main container" role="main">
        <div className="app__title-wr">
          <button className="button button--back" onClick={backHandler}>
            <BackIcon />
          </button>
          <h2 className="app__title">{t("sender")}</h2>
        </div>

        <form className="input__list">
          <div className={`input__item ${nameVal ? "error" : ""}`}>
            <label className="input__label-top" htmlFor="name">
              {t("name")}
            </label>
            <input
              className="input"
              id="name"
              name="name"
              value={name}
              onChange={handleChange}
              onBlur={validateOne}
            />
            <div className="input__error">{nameVal}</div>
          </div>

          <div className={`input__item ${surnameVal ? "error" : ""}`}>
            <label className="input__label-top" htmlFor="surname">
              {t("surname")}
            </label>
            <input
              className="input"
              id="surname"
              name="surname"
              value={surname}
              onChange={handleChange}
              onBlur={validateOne}
            />
            <div className="input__error">{surnameVal}</div>
          </div>

          <div className={`input__item ${emailVal ? "error" : ""}`}>
            <label className="input__label-top" htmlFor="email">
              Email
            </label>
            <input
              className="input"
              id="email"
              type="email"
              name="email"
              value={email}
              onChange={handleChange}
              onBlur={validateOne}
            />
          </div>
          <div className="input__error">{emailVal}</div>
        </form>
      </main>
      <div className="app__bottom">
        <button
          className="button"
          onClick={nextHandler}
          disabled={!isFormValid}
        >
          {t("addSender")}
        </button>
      </div>
    </>
  );
};

export default ThirdStep;
