import { React, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as BackIcon } from "../../img/back.svg";
import { START_TRANSACTION } from "../../apollo";
import { useMutation } from "@apollo/client";
import { UPDATE_TRANSACTION } from "../../apollo";

const SecondStep = ({ backHandler, transactionData, setTransactionData }) => {
  const { transactionId, mainCurrency, currencyFromAmount, currencyToAmount } =
    transactionData;
  const { t } = useTranslation();
  const [seconds, setSeconds] = useState(60);
  const [isDisabled, setIsDisabled] = useState(false);
  const [startOrder, setStartOrder] = useState(false);
  const [data, setData] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updateTransaction] = useMutation(UPDATE_TRANSACTION);
  const [startTransaction] = useMutation(START_TRANSACTION);

  const mutationParams = {
    variables: {
      transactionId: transactionData.transactionId,
    },
  };

  if (mainCurrency === "EUR") {
    mutationParams.variables.currencyFromAmount = +currencyFromAmount;
  } else {
    mutationParams.variables.currencyToAmount = +currencyToAmount;
  }

  const orderHandler = async () => {
    setStartOrder(true);

    try {
      const data = await startTransaction({
        variables: {
          transactionId: transactionId,
        },
      });
      // if(data?.data?.startTransaction?.transaction?.depositUrl){
        window.location = data?.data?.startTransaction?.transaction?.depositUrl;
      // }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await updateTransaction(mutationParams);
      setData(data?.data?.updateTransaction?.transaction);
      const newData = data?.data?.updateTransaction?.transaction;

      setTransactionData((prev) => {
        return {
          ...prev,
          ...newData,
        };
      });
      setLoading(false);
    };
    fetchData().catch((e) => {
      setError(e);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        setIsDisabled(true);

        const fetchData = async () => {
          const data = await updateTransaction(mutationParams);

          const newData = data?.data?.updateTransaction?.transaction;
          setData(data?.data?.updateTransaction?.transaction);
          setTransactionData((prev) => {
            return {
              ...prev,
              ...newData,
            };
          });
          clearInterval(interval);
          setIsDisabled(false);
          setSeconds(60);
        };
        fetchData().catch((e) => {
          setError(e);
        });
      }
    }, 1000);

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  if (loading) return null;
  if (error) return `Error! ${error}`;
  return (
    <>
      <main className="app__main container" role="main">
        <div className="app__title-wr">
          <button className="button button--back" onClick={backHandler}>
            <BackIcon />
          </button>
          <h2 className="app__title">{t("confirmation")}</h2>
        </div>

        <div className="confirm">
          <p className="confirm__item">
            <span>{t("receipient")}</span>
            <span>{data.recepient}</span>
          </p>

          <p className="confirm__item">
            <span>{t("receive")}</span>
            <span>
              {data.currencyFromAmount} {data.currencyFrom}
            </span>
          </p>

          <p className="confirm__item">
            <span>{t("get")}</span>
            <span>
              {data.currencyToAmount} {data.currencyTo}
            </span>
          </p>

          <p className="confirm__item">
            <span>{t("fee")}</span>
            <span>{t("feeDesc")}</span>
          </p>

          <p className="confirm__item">
            <span>{t("rate")}</span>
            <span>
              <span className="confirm__timer">
                {seconds}
                {t("second")}
              </span>
              {data.effectiveRate} {data.currencyTo}/{data.currencyFrom}
            </span>
          </p>
        </div>
        <div className="info__item primary">{t("transers")} </div>
      </main>
      <div className="app__bottom">
        <button
          className="button"
          onClick={orderHandler}
          disabled={isDisabled || startOrder}
        >
          {t("makeOrder")}
        </button>
      </div>
    </>
  );
};

export default SecondStep;
