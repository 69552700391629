import React, { FC } from "react";
import Icon from "../../img/like.svg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SuccessInfo: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="result__inner">
        <div className="result__icon">
          <Icon />
        </div>
        <div className="result">
          <p>{t("executed")}</p>
          <p>{t("notification")}</p>
        </div>
      </div>
      <div className="app__bottom">
        <Link to="/" className="button">
          {t("moreTransfer")}
        </Link>
      </div>
    </>
  );
};

export default SuccessInfo;
