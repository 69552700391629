import {
  gql
} from "@apollo/client";

const GET_REFERENCE_DATA = gql `
  query GetReferenceData(
    $currencyFrom: CurrencyFrom!
    $currencyTo: CurrencyTo!
  ) {
    getReferenceData(currencyFrom: $currencyFrom, currencyTo: $currencyTo) {
      minDeposit
      maxDeposit
      minWithdraw
      maxWithdraw
      effectiveRate
    }
  }
`;

// {
//   "currencyFrom": "EUR",
//   "currencyTo": "UAH"
// }

const СREATE_TRANSACTION = gql `
  mutation CreateTransaction(
    $userId: ID!
    $currencyFrom: CurrencyFrom!
    $currencyTo: CurrencyTo!
    $currencyFromAmount: Float
  ) {
    createTransaction(
      userId: $userId
      currencyFrom: $currencyFrom
      currencyTo: $currencyTo
      currencyFromAmount: $currencyFromAmount
    ) {
      error
      success
      transaction {
        id
        createdAt
        updatedAt
        startedAt
        completedAt
        currencyFrom
        currencyFromAmount
        currencyTo
        currencyToAmount
        effectiveRate
        recepient
        depositUrl
      }
    }
  }
`;
// {
//   "userId": "a089dfa4-5607-42f1-b77e-db356fc83a58",
//   "currencyFrom": "EUR",
//   "currencyTo": "UAH",
//   "currencyFromAmount": 1000
// }

const UPDATE_TRANSACTION = gql `
  mutation UpdateTransaction(
    $transactionId: ID!
    $currencyFromAmount: Float
    $currencyToAmount: Float
    $recipient: String,
    $customerFirstName : String,
    $customerLastName : String, 
    $customerEmail: String
  ) {
    updateTransaction(
      transactionId: $transactionId
      currencyFromAmount: $currencyFromAmount
      currencyToAmount: $currencyToAmount
      recipient: $recipient
      customerFirstName: $customerFirstName
      customerLastName: $customerLastName
      customerEmail: $customerEmail 
    ) {
      error
      success
      transaction {
        id
        createdAt
        updatedAt
        startedAt
        completedAt
        currencyFrom
        currencyFromAmount
        currencyTo
        currencyToAmount
        effectiveRate
        recepient
        depositUrl
        customerEmail
        customerLastName
        customerFirstName
      }
    }
  }
`;

// {
//   "transactionId": "deb88a31-6c95-4c4c-8eb7-58971552bee5",
//   "currencyToAmount": 50000
// }

const START_TRANSACTION = gql `
  mutation StartTransaction($transactionId: ID!) {
    startTransaction(transactionId: $transactionId) {
      error
      success
      transaction {
        id
        createdAt
        updatedAt
        startedAt
        completedAt
        currencyFrom
        currencyFromAmount
        currencyTo
        currencyToAmount
        effectiveRate
        recepient
        depositUrl
      }
    }
  }
`;

const GET_TRANSACTION_HISTORY = gql `
  query GetTransactionsHistory($userId: ID!) {
    getTransactionsHistory(userId: $userId) {
      id
      createdAt
      updatedAt
      startedAt
      completedAt
      currencyFrom
      currencyFromAmount
      currencyTo
      recepient
      depositUrl
      withdrawnAt
      cancelledAt
      status
      sender
      currencyFromAmount
      currencyToAmount
      effectiveRate
    }
  }
`;

// {
//   "userId": "a089dfa4-5607-42f1-b77e-db356fc83a58"
// }

// {
//   "transactionId": "deb88a31-6c95-4c4c-8eb7-58971552bee5"
// }

// /id

const GET_TRANSACTION = gql `
  query GetTransaction($transactionId: ID!) {
    getTransaction(transactionId: $transactionId) {
      id
      startedAt
      depositedAt
      cancelledAt
      status
    }
  }
`;

// {
//   "transactionId": "9f580de3-c152-466f-919b-e065551b3af3"
// }

export {
  GET_REFERENCE_DATA,
  СREATE_TRANSACTION,
  UPDATE_TRANSACTION,
  START_TRANSACTION,
  GET_TRANSACTION_HISTORY,
  GET_TRANSACTION,
};