import { FC } from "react";
import { ReactComponent as CanceledIcon } from "../../img/canceled.svg";
import { ReactComponent as PandingIcon } from "../../img/pending.svg";
import { ReactComponent as DoneIcon } from "../../img/done.svg";

type Status = "WaitDeposit" | "Cancelled" | "Processing" | "Finished";

interface HistoryIconProps {
    status: Status;
}

const HistoryIcon: FC<HistoryIconProps> = ({ status }) => {
    switch (status) {
        case "Finished":
            return <DoneIcon />;
        case "Cancelled":
            return <CanceledIcon />;
        default:
            return <PandingIcon />
    }
}

export default HistoryIcon;