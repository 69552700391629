import { createBrowserRouter } from "react-router-dom";
// import App from "../App";
import ErrorPage from "../components/Pages/ErrorPage";
import SuccessPage from "../components/Pages/SuccessPage.tsx";
import HomePage from "../components/Pages/HomePage";
// import HistoryPage from "../components/Pages/HistoryPage"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
    errorElement: <HomePage />,
  },
  {
    path: "error",
    element: <ErrorPage />,
  },
  {
    path: "success/transaction/:transactionId",
    element: <SuccessPage />,
  },
  // {
  //   path: "history/:id",
  //   element: <HistoryPage />,
  // },
]);
