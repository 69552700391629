import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { GET_TRANSACTION } from "../../apollo";
import useTimer from "../../hooks/useTimer.tsx";
import { useQuery } from "@apollo/client";
import SuccessInfo from "../Success/SuccessInfo.tsx";
import Loader from "../Loader/Loader";
import ErrorInfo from "../Errors/ErrorInfo";
import Header from "../Header/Header";

const FETCH_INTERVAL = 10;

const SuccessPage: FC = () => {
  let { transactionId } = useParams<string>();
  const { loading, error, data, refetch } = useQuery(GET_TRANSACTION, {
    variables: { transactionId: transactionId },
  });

  useTimer(refetch, FETCH_INTERVAL);

  return (
    <div className="app">
      <Header />
      {loading ||
        (
          data?.getTransaction?.status === "WaitDeposit" && (
            <div className="app__main center container">
              <Loader />
            </div>
          ))}
      {(data?.getTransaction?.status === "Cancelled" || error) && <ErrorInfo />}
      {(data?.getTransaction?.status === "Processing" || data?.getTransaction?.status === "Finished") && <SuccessInfo />}
    </div>
  );
};

export default SuccessPage;
