import {
  useEffect,
  useState
} from "react";

const useTimer = (callBack: any, timeInterval: number = 60): void => {
  const [seconds, setSeconds] = useState(timeInterval);

  useEffect(() => {
    let interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        callBack();
        clearInterval(interval);
        setSeconds(timeInterval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timeInterval, callBack, seconds]);
};

export default useTimer;