import { useTranslation } from "react-i18next";
import { GET_TRANSACTION_HISTORY } from "../../apollo";
import { useQuery } from "@apollo/client";
import useTimer from "../../hooks/useTimer.tsx";
import HistoryIcon from "./HistoryIcon.tsx";
import "./history.scss";

const History = ({ userId, historyItemHandler }) => {
  const { t } = useTranslation();
  const { loading, error, data, refetch } = useQuery(GET_TRANSACTION_HISTORY, {
    variables: { userId: userId },
  });

  useTimer(refetch);

  if (loading || error || !data?.getTransactionsHistory?.length) return null;

  const groups = data?.getTransactionsHistory?.reduce((groups, item) => {
    const date = item.createdAt.split("T")[0];
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(item);
    return groups;
  }, []);

  return (
    <div className="history">
      <h2 className="history__title">{t("recent")}</h2>
      <div className="history__list">
        {Object.entries(groups).map(([key, value]) => {
          return (
            <div className="history__item" key={key}>
              <time>{key}</time>
              {value &&
                value.length &&
                value.map((item) => {
                  return (
                    <button className="history__row" key={item.id} onClick={()=> historyItemHandler(item.id)}>
                      {item.recepient} {"-"}
                      <b>
                        {" "}
                        {item.currencyFromAmount} {item.currencyFrom}
                      </b>
                      <span className="history__icon">
                        <HistoryIcon status={item.status} />
                      </span>
                    </button>
                  );
                })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default History;
