import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as EuSvg } from "../../img/eu.svg";
import { ReactComponent as UkrSvg } from "../../img/ukr.svg";
import { useMutation } from "@apollo/client";
import { UPDATE_TRANSACTION } from "../../apollo";

const EUR = "EUR";
const UAH = "UAH";

const FirstStepForm = ({ undateValues, transactionData, disableButton }) => {
  const {
    maxDeposit,
    maxWithdraw,
    minDeposit,
    minWithdraw,
    transactionId,
    currencyFromAmount,
    currencyToAmount,
  } = transactionData;
  const { t } = useTranslation();
  const [updateTransaction] = useMutation(UPDATE_TRANSACTION);
  const [from, setFrom] = useState(transactionData.currencyFromAmount || "");
  const [errorFrom, setErrorFrom] = useState(null);
  const [to, setTo] = useState(transactionData.currencyToAmount || "");
  const [errorTo, setErrorTo] = useState(null);

  const blurHandlerFrom = async (e) => {
    e.preventDefault();
    const value = e.target.tagName === "FORM" ? from : e.target.value;
    if (+value === +currencyFromAmount) return;

    disableButton();
    setErrorTo(null);

    if (!value) {
      setErrorFrom(null);
      return;
    }
    let error = null;
    if (value < minDeposit) error = "minDeposit/EUR";
    if (value > maxDeposit) error = "maxDeposit/EUR";
    setErrorFrom(error);

    const data = await updateTransaction({
      variables: {
        transactionId: transactionId,
        currencyFromAmount: Number(value),
      },
    });

    setTo(data?.data?.updateTransaction?.transaction?.currencyToAmount || to);

    if (!error) {
      if (
        data?.data?.updateTransaction?.transaction?.currencyToAmount >
        maxWithdraw
      )
        error = "maxWithdraw/UAH";
      if (
        data?.data?.updateTransaction?.transaction?.currencyToAmount <
        minWithdraw
      )
        error = "minWithdraw/UAH";
      setErrorFrom(error);
    }

    undateValues(
      {
        currencyFromAmount: value,
        currencyToAmount:
          data?.data?.updateTransaction?.transaction?.currencyToAmount,
        mainCurrency: "EUR",
        effectiveRate:
          data?.data?.updateTransaction?.transaction?.effectiveRate,
      },
      error
    );
  };

  const blurHandlerTo = async (e) => {
    e.preventDefault();
    const value = e.target.tagName === "FORM" ? to : e.target.value;
    if (+value === +currencyToAmount) return;

    disableButton();
    setErrorFrom(null);

    let fromValue = null;
    let toValue = null;
    let error = null;

    if (!value) {
      setErrorTo(null);
      return;
    }

    const data = await updateTransaction({
      variables: {
        transactionId: transactionId,
        currencyToAmount: Number(value),
        mainCurrency: "EUR",
      },
    });

    fromValue =
      data?.data?.updateTransaction?.transaction?.currencyFromAmount || from;
    toValue =
      data?.data?.updateTransaction?.transaction?.currencyToAmount || to;

    setFrom(fromValue);
    setTo(toValue);

    if (toValue > maxWithdraw) error = "maxWithdraw/UAH";
    if (toValue < minWithdraw) error = "minWithdraw/UAH";

    setErrorTo(error);

    if (!error) {
      if (fromValue < minDeposit) error = "minDeposit/EUR";
      if (fromValue > maxDeposit) error = "maxDeposit/EUR";
      setErrorFrom(error);
    }

    undateValues(
      {
        currencyFromAmount: fromValue,
        currencyToAmount: toValue,
        mainCurrency: "UAH",
        effectiveRate:
          data?.data?.updateTransaction?.transaction?.effectiveRate,
      },
      error
    );
  };

  const changeHandler = (e) => {
    const isEUR = e.target.id === EUR;
    const value = e.target.value;
    isEUR ? setFrom(value) : setTo(value);
  };

  return (
    <div className="input__list">
      <form className="input__item" onSubmit={blurHandlerFrom}>
        <div className="input__title">{t("send")}</div>
        <div className="input__inner">
          <EuSvg />
          <div className="input__subtitle">{EUR}</div>
          <div className="input__inner-wrap">
            <input
              id={EUR}
              className={`input ${errorFrom ? "error" : ""}`}
              type="number"
              value={from}
              onBlur={blurHandlerFrom}
              onChange={changeHandler}
            />
            <label className="input__label" htmlFor="eu">
              {EUR}
            </label>
          </div>
        </div>
      </form>
      <form className="input__item" onSubmit={blurHandlerTo}>
        <div className="input__title">{t("receive")}</div>
        <div className="input__inner">
          <UkrSvg />
          <div className="input__subtitle">{UAH}</div>
          <div className="input__inner-wrap">
            <input
              id={UAH}
              className={`input ${errorTo ? "error" : ""}`}
              type="number"
              value={to}
              onBlur={blurHandlerTo}
              onChange={changeHandler}
            />
            <label className="input__label" htmlFor="ua">
              {UAH}
            </label>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FirstStepForm;
