import React, { useState, useEffect } from "react";
import { ReactComponent as Logo } from "../../img/logo.svg";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const langOptions = [
  { value: "ukr", label: "UKR" },
  { value: "eng", label: "ENG" },
];

const Header = () => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState(langOptions[0]);

  useEffect(() => {
    i18n.changeLanguage(selectedOption.value);
  }, [selectedOption]);

  return (
    <header className="app__top">
      <div className="container">
        <div className="app__top-inner">
          <Logo />
          <div className="lang_block">
            <div className="lang_name">{t("language")}</div>
            <Select
              value={selectedOption}
              onChange={setSelectedOption}
              options={langOptions}
              isSearchable={false}
              className="select-container"
              classNamePrefix="select"
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
