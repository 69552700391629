import React from "react";

import { ReactComponent as BackIcon } from "../../img/back.svg";
import { useTranslation } from "react-i18next";
import HistoryIcon from "../History/HistoryIcon.tsx";
import useTimer from "../../hooks/useTimer.tsx";
import { GET_TRANSACTION_HISTORY } from "../../apollo";
import { useQuery } from "@apollo/client";
import Loader from "../Loader/Loader";
import moment from "moment";

const HistoryItem = ({ userId, historyItemHandler, historyItemId }) => {
  const { t } = useTranslation();

  const { loading, data, refetch } = useQuery(GET_TRANSACTION_HISTORY, {
    variables: { userId: userId },
  });

  const historyItem = data?.getTransactionsHistory?.find(
    (item) => item.id === historyItemId
  );

  const date = historyItem
    ? moment(historyItem?.startedAt).format("YYYY-MM-DD  HH:mm:ss")
    : null;

  useTimer(refetch);

  return (
    <main
      className={`app__main container ${loading ? "center" : ""}`}
      role="main"
    >
      {loading && <Loader />}

      <div className="app__title-wr">
        <button className="button button--back" onClick={historyItemHandler}>
          <BackIcon />
        </button>

        {historyItem && (
          <>
            <h2 className="app__title">
              <span className="app__title-inner">
                {historyItem.currencyFromAmount} {historyItem.currencyFrom}
                <span className="history__icon app__title-icon">
                  <HistoryIcon status={historyItem.status} />
                </span>
              </span>
            </h2>
            <div className="app__subtitle">{date}</div>
          </>
        )}
      </div>

      {historyItem && (
        
        <div className="confirm">
          {historyItem.sender && (
            <p className="confirm__item">
              <span>{t("sender")}</span>
              <span>{historyItem.sender}</span>
            </p>
          )}

          <p className="confirm__item">
            <span>{t("receipient")}</span>
            <span>{historyItem.recepient}</span>
          </p>

          {historyItem.status === "Finished" ? (
            <>
              <p className="confirm__item">
                <span>{t("get")}</span>
                <span>
                  {historyItem.currencyToAmount} {historyItem.currencyTo}
                </span>
              </p>
              <p className="confirm__item">
                <span>{t("rate")}</span>
                <span>
                  {historyItem.effectiveRate} {historyItem.currencyTo}/
                  {historyItem.currencyFrom}
                </span>
              </p>
            </>
          ) : (
            <p className="confirm__item">
              <span>{t("status")}</span>
              <span>
                {historyItem.status === "Declined"
                  ? t("declined")
                  : t("pending")}
              </span>
            </p>
          )}
        </div>
      )}
    </main>
  );
};

export default HistoryItem;
