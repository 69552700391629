import { React, useState } from "react";
import { useTranslation } from "react-i18next";
import History from "../History/History";
import Errors from "../Errors/Errors";
import FirstStepForm from "./FirstStepForm";

const FirstStep = ({
  nextStepHandler,
  transactionData,
  setTransactionData,
  userId,
  historyItemHandler
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(false);
  const [isValid, setIsValid] = useState(true);

  const undateValues = (data, message) => {
    setError(message);
    setIsValid(!message);
    setTransactionData((prev) => {
      return {
        ...prev,
        ...data,
      };
    });
  };

  return (
    <>
      <main className="app__main container" role="main">
        <FirstStepForm
          undateValues={(values, message) => undateValues(values, message)}
          transactionData={transactionData}
          disableButton={() => setIsValid(false)}
        />
        <div className="info__item">
          <p>
            {t("rate")}{" "}
            {transactionData?.effectiveRate
              ? transactionData?.effectiveRate
              : " "}{" "}
            UAH/EUR
          </p>
          <p>{t("noCharge")}</p>
        </div>

        {error && <Errors referenceData={transactionData} message={error} />}

        <div className="info__item primary">
          <p>{t("ourRates")}</p>
          <p>
            {t("are")} <span className="info__highlighted">7%</span>{" "}
            {t("better")}
          </p>
          <p>{t("cheaper")}</p>
        </div>

        <div className="info__item secondary">
          <p>{t("needHelp")}</p>
          <p>
            {t("writeUs")}{" "}
            <a href="mailto:support@mover.cash">support@mover.cash</a>
          </p>
        </div>
        
        <History userId={userId} historyItemHandler={(historyItem)=> historyItemHandler(historyItem)}/>
      </main>
      <div className="app__bottom">
        <button
          className="button"
          onClick={nextStepHandler}
          disabled={
            !isValid ||
            (!(
              transactionData.currencyFromAmount &&
              transactionData.currencyToAmount
            ) &&
              !error)
          }
        >
          {t("makeOrder")}
        </button>
      </div>
    </>
  );
};

export default FirstStep;
