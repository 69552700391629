import React from "react";
import { ReactComponent as Icon } from "../../img/error.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ErrorInfo = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="result__inner">
        <div className="result__icon">
          <Icon />
        </div>
        <div className="result">
          <p>{t("transfer")}</p>
          <p>{t("moneyBack")}</p>
          <p>{t("severalDays")}</p>
        </div>
      </div>
      <div className="app__bottom">
        <Link to="/" className="button">
          {t("time")}
        </Link>
      </div>
    </>
  );
};

export default ErrorInfo;
