import React from 'react';
import ReactDOM from "react-dom/client";
import { ApolloClient, InMemoryCache, ApolloProvider,from } from '@apollo/client';
import { router } from "./router/index";
import { RouterProvider } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { RetryLink } from "@apollo/client/link/retry";
import { HttpLink } from '@apollo/client';
import { onError } from "@apollo/client/link/error";

import "./app.scss";
import "./index.scss";

const retryLink = new RetryLink();
const httpLink = new HttpLink({ uri: process.env.REACT_APP_SERVER});
const query = new URLSearchParams(window.location.search);
const userId = query.get('unique_id') || uuidv4();
// uuidv4 for dev mode

if (!localStorage.userId) {
  localStorage.setItem("userId", userId);
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});
  
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink,retryLink, httpLink]),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
    <RouterProvider router={router} />
  </ApolloProvider>
);
